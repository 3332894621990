import React from 'react';
import { Col, Container, Grid, Title } from '@aviationexam/core';
import { graphql, PageProps } from 'gatsby';
import { SEO } from 'components';
import { usePublicTranslation } from 'features/localization';
import { PostNode } from 'types/post';
import { BlogPostThumbnail } from './components';
import useStyles from './Blog.styles';

interface DataType {
  allBlogPost: {
    edges: Array<{
      node: PostNode;
    }>;
  };
}

interface Context {
  locale: 'en' | 'de';
  tag: string;
  tagLabel: string;
}

function BlogTagged({
  data: { allBlogPost },
  pageContext: { locale, tagLabel },
}: PageProps<DataType, Context>) {
  const { t } = usePublicTranslation();
  const { classes } = useStyles();

  return (
    <>
      <SEO title={t('blogPage.title')} description={t('site.description')} lang={locale} />
      <Container size="xl" className={classes.container}>
        <Grid>
          <Col span={12}>
            <Title order={1} my="xl" className={classes.title}>
              {t('blogPage.tagHeading', { tag: tagLabel })}
            </Title>
          </Col>
          {allBlogPost.edges.map(({ node }, i) => (
            <Col key={node.id} span={12} xs={i === 0 ? 12 : 6} md={i === 0 ? 12 : 4}>
              <BlogPostThumbnail
                title={node.frontmatter.postTitle[locale]}
                author={node.frontmatter.author}
                date={node.frontmatter.date}
                locale={node.frontmatter.locale}
                route={node.frontmatter.urls[locale] ?? node.frontmatter.urls.en}
                description={node.frontmatter.postDescription[locale]}
                featuredImage={node.frontmatter.featuredImage}
                featured={i === 0}
              />
            </Col>
          ))}
        </Grid>
      </Container>
    </>
  );
}

export default BlogTagged;

export const pageQuery = graphql`
  query BlogPostsByTag($tag: String!) {
    allBlogPost(
      filter: { frontmatter: { tags: { elemMatch: { tagKey: { eq: $tag } } } } }
      sort: { fields: frontmatter___date, order: DESC }
    ) {
      edges {
        node {
          id
          frontmatter {
            postTitle {
              en
              de
            }
            author
            date
            postDescription {
              en
              de
            }
            urls {
              en
              de
            }
            locale
            featuredImage {
              childImageSharp {
                gatsbyImageData(
                  width: 1000
                  quality: 100
                  placeholder: BLURRED
                  formats: [WEBP, AUTO]
                )
              }
            }
          }
        }
      }
    }
  }
`;
